<template>
  <el-dialog
      title="数字资源最新上传记录用户表"
      :visible.sync="dialogVisible"
      width="1000px"
      class="dialogTable"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="handleClose">
    <el-form size="small" :inline="true" ref="searchForm" :model="searchForm"
             label-width="100px">
      <el-form-item prop="phone" label="手机号">
        <el-input v-model="searchForm.phone" placeholder="请输入手机号" clearable></el-input>
      </el-form-item>
      <el-form-item prop="name" label="姓名">
        <el-input v-model="searchForm.name" placeholder="请输入姓名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="getDataList(1)"
                   icon="el-icon-search">
          查询
        </el-button>
        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置
        </el-button>
      </el-form-item>
      <el-button size="small" class="f_r" icon="el-icon-plus" type="primary" @click="addUser()">添加用户</el-button>
    </el-form>
    <el-table
        :data="dataList"
        size="small"
        v-loading="loading"
        ref="multipleTable"
        height="calc(100vh - 345px)"
        class="table"
    >
      <el-table-column prop="image" label="头像" width="50">
        <template slot-scope="scope">
          <el-image
              style="width: 35px; height: 35px"
              :src="scope.row.image"
              :fit="'fill'"
              :preview-src-list="[scope.row.image]"
          >
            <div slot="error" class="image-slot">
              <el-image
                  style="width: 35px; height: 35px"
                  :src="require('@/assets/img/defaultAvatar.png')"
                  :fit="'fill'"
              ></el-image>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
      <el-table-column prop="email" label="邮箱" show-overflow-tooltip></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="removeData(scope.row)">
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text_center" style="margin-top: 15px">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <add-user-table ref="addUserTable" @refresh="getDataList()"></add-user-table>
  </el-dialog>
</template>

<script>
import AddUserTable from "@/views/modules/workbench/addUserTable.vue";

export default {
  name: "uploadRecordUserTable",
  components: {AddUserTable},
  data() {
    return {
      dialogVisible: false,
      searchForm: {
        phone: '',
        name: '',
      },
      dataList: [],
      loading: false,
      current: 1,
      size: 10,
      total: 0,
    }
  },

  methods: {
    init() {
      this.dialogVisible = true
      this.getDataList(1)
    },

    getDataList(type) {
      if (type == 1){
        this.current = 1
      }
      this.loading = true
      this.$axios(this.api.auth.recodesettingList, {
        ...this.searchForm,
        current:this.current,
        size:this.size,
      }, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    resetting(){
      this.$refs.searchForm.resetFields()
      this.getDataList(1)
    },

    removeData(row) {
      this.$confirm(`确定移除该用户记录吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.auth.recodesettingRemoveById + row.id, {}, 'get').then(data => {
          if (data.status) {
            this.$message.success('移除该用户记录成功')
            this.getDataList()
          } else {
            this.$message.error('移除该用户记录失败')
          }
        })
      })
    },

    //添加用户
    addUser(){
      this.$refs.addUserTable.init()
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },

    handleClose() {
      this.$emit('refresh')
      this.resetting()
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>

</style>